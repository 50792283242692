import $ from '../core/Dom';
import hljs from 'highlight.js/lib/core';

import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import twig from 'highlight.js/lib/languages/twig';
import css from 'highlight.js/lib/languages/css';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('php', php);
hljs.registerLanguage('twig', twig);
hljs.registerLanguage('css', css);

export default el => {
    const init = () => {
        hljs.highlightBlock($(el).find('code').get(0));
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
