import $ from '../core/Dom';

export default el => {
    const colours = ['#e5c78e', '#d7dae8', '#d4c0cf', '#bcbcbc', '#dce0e1', '#b2cec0'];

    let stars = [];
    let canvas = null;
    let context = null;

    const init = () => {
        canvas = el;
        context = canvas.getContext('2d');

        canvas.width = $(el).width();
        canvas.height = $(el).height();

        for (let i = 0, l = 100; i < l; i++) {
            const radius = Math.random() * 1.8;
            stars.push({
                'startRadius': radius,
                'radius': radius,
                'x': Math.random() * canvas.width,
                'y': Math.random() * canvas.height,
                'colour': colours[parseInt(Math.random() * 4)],
                'blur': 8 + (Math.random() * 8),
                'pulse': true,
                'threshold': (radius * 1.15),
                'randomSpeed': Math.random() * 0.04
            });
        }
        
        window.requestAnimationFrame(draw);
    };

    const generatePulseVariance = (star, canvas) => {
        if (star.pulse) {
            star.radius += 0.075;
            star.pulse = (star.radius <= star.threshold);
        } else {
            if (star.radius >= 1) {
                star.radius -= 0.075;
            }
            star.pulse = (star.radius <= 1);
        }

        if (star.x < canvas.width)
        {
            //star.x += 0.02 + (star.startRadius/20);
            star.x += 0.06 + star.randomSpeed;
        } else {
            star.x = 0;
        }

        return star;
    };

    const draw = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);

        for (let i = 0, l = stars.length; i < l; i++) {
            let star = stars[i];
            star = generatePulseVariance(star, canvas);
            context.beginPath();
            context.arc(star.x, star.y, star.radius, 0, 2 * Math.PI, false);
            context.fillStyle = star.colour;
            context.shadowColor = star.colour;
            context.shadowBlur = star.blur;
            context.fill();
        }

        window.requestAnimationFrame(draw);
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
