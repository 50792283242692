import $ from '../core/Dom';
//import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default el => {
    const $el = $(el);
    const baseUri = '/assets/img/';
    const imageFilenames = ['loading-002-katana.png', 'loading-003-yin-yang.png', 'loading-009-quiver.png', 'loading-011-shuriken.png', 'loading-012-tonfa.png', 'loading-023-ninja-4.png', 'loading-037-dummy.png', 'loading-040-fan.png', 'loading-046-nunchaku-1.png'];

    let items = [];
    let isRunning = false;

    const init = () => {
        /*
        for (let i = 0; i < 15; i++) {
            let $item = $('<img class="absolute" style="width: 50px; height: 50px;" src="' + baseUri + imageFilenames[Math.floor(Math.random()*imageFilenames.length)] + '" aria-hidden="true">');
            items.push($item);
            $el.append($item);
        }
        
        positionItems();
        
        Dispatch.on(eventKeys.PAGE_CHANGED, onPageChanged);
        
         */
    };
    /*
    const onPageChanged = () => {
        positionItems();
        run();
    };

    const positionItems = () => {
        const vw = Viewport.width;
        const vh = Viewport.height;
        
        items.forEach($value => {
            gsap.set($value.get(0), { x: Math.round(Math.random()*vw), y: -200, rotation: Math.random()*360 });
        });
    };
    
    const run = () => {
        const vw = Viewport.width;
        const vh = Viewport.height;

        items.forEach(($value, index) => {
            gsap.to($value.get(0), { duration: 1 + (Math.random() / 2), delay: Math.random() * 1, ease: 'Sine.easeIn', y: vh + 50, rotation: '+=' + (40-(Math.random()*80)) });
        });
    };
    *
     */

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
