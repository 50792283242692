import $ from '../core/Dom';

export default el => {
    const $el = $(el);
    const $script = $el.find('script');

    let isLoaded = false;

    const init = () => {
        const options = {
            rootMargin: '0px',
            threshold: 0.0
        };

        const observer = new IntersectionObserver(onIntersect, options);
        observer.observe(el);
    };

    const destroy = () => {

    };

    const onIntersect = (entries, observer) => {
        entries.forEach(entry => {
            console.log(entry.intersectionRatio);
            if (entry.intersectionRatio > 0) {
                //console.log('her', $script.data('src'));
                //window.commento.main();
                
                $script.attr('src', $script.data('src'));
                observer.disconnect();
            }
        });
    };

    return {
        init,
        destroy
    };
};
