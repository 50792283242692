import barba from '@barba/core';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

const init = () => {
    Viewport.init();
    Viewport.initTabbing();
    ViewportHeight.init();

    pageInits();

    barba.init({
        timeout: 10000,
        views: [
            {
                namespace: 'frontpage',
                beforeEnter() {
                    // update the menu based on user navigation
                    //menu.update();
                    //Dispatch.emit(eventKeys.PAGE_CHANGED);
                    $('body').addClass('-hide-header');

                },
                afterEnter() {
                    // refresh the parallax based on new page content
                    //parallax.refresh();
                    pageInits();
                }
            },
            {
                namespace: 'blogArticle',
                beforeEnter() {
                    // update the menu based on user navigation
                    //menu.update();
                    //Dispatch.emit(eventKeys.PAGE_CHANGED);
                    $('body').removeClass('-hide-header');
                },
                afterEnter(data) {
                    // refresh the parallax based on new page content
                    pageInits();
                }
            }
        ],
        requestError: (trigger, action, url, response) => {
            // go to a custom 404 page if the user click on a link that return a 404 response status
            console.log('her', trigger, action, url, response);
            if (action === 'click' && response.status && response.status === 404) {
                barba.go('/404');
            }

            // prevent Barba from redirecting the user to the requested URL
            // this is equivalent to e.preventDefault() in this context
            return false;
        }
    });

    if (window.history.scrollRestoration) {
        //window.history.scrollRestoration = 'manual';
    }

    barba.hooks.after(data => {
        console.log('her', data);
        if (data.trigger !== 'back') {
            window.scrollTo(0, 0);
        }
    });
};

const pageInits = () => {
    lazySizes.init();
    Components.init();
};

require('doc-ready')(init);
